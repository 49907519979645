import React from "react";
import sanitizeInput from "../../../hooks/sanitizeInput";

const PswPageForm = ({ values, setValues, copySite, copyUser }) => {
  return (
    <form
      autoComplete="off"
      className="w-full flex flex-col justify-center items-center p-4 mx-auto"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex flex-col justify-center items-center w-full my-2 mx-auto">
        <label
          htmlFor="site"
          className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Site{" "}
          <svg
            width="7"
            height="7"
            className="ml-1"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
              fill="#EF4444"
            />
          </svg>
        </label>
        <input
          type="text"
          id="site"
          name="site"
          className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
          value={values.site}
          maxLength={200}
          onChange={(e) =>
            setValues({
              ...values,
              [e.target.name]: sanitizeInput(e.target.value),
            })
          }
          onClick={() => copySite()}
        />
      </div>

      <div className="flex flex-col justify-center items-center w-full my-2 mx-auto">
        <label
          htmlFor="user"
          className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Username{" "}
          <svg
            width="7"
            height="7"
            className="ml-1"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
              fill="#EF4444"
            />
          </svg>
        </label>
        <input
          id="user"
          name="user"
          className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
          value={values.user}
          maxLength={100}
          onChange={(e) =>
            setValues({
              ...values,
              [e.target.name]: sanitizeInput(e.target.value),
            })
          }
          onClick={() => copyUser()}
          type="text"
        />
      </div>

      <div className="flex flex-col justify-center items-center w-full my-2 mx-auto">
        <label
          htmlFor="psw"
          className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Password{" "}
          <svg
            width="7"
            height="7"
            className="ml-1"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
              fill="#EF4444"
            />
          </svg>{" "}
        </label>

        <input
          id="psw"
          name="psw"
          className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
          value={values.psw}
          maxLength="50"
          onChange={(e) =>
            setValues({
              ...values,
              [e.target.name]: sanitizeInput(e.target.value),
            })
          }
          type="text"
        />
      </div>
    </form>
  );
};

export default PswPageForm;
