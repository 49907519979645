import React from "react";
import Spinner from "../loaders/Spinner";
import sanitizeInput from "../../../hooks/sanitizeInput";

const ResetPasswordForm = ({
  formRef,
  handleSubmit,
  resetLoading,
  userEmail,
  password,
  setPassword,
}) => {
  return (
    <form
      ref={formRef}
      className="relative flex flex-col justify-center items-center w-full"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="text-center w-full flex justify-center items-center mt-3 min-h-6">
        {resetLoading && <Spinner />}
      </div>

      <div className="flex flex-col justify-center items-center w-full my-2 mx-auto">
        <label
          htmlFor="email"
          className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Email{" "}
          <svg
            width="7"
            height="7"
            className="ml-1"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
              fill="#EF4444"
            />
          </svg>
        </label>
        <input
          className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
          type="email"
          placeholder="Email"
          autoFocus
          title="Insert email"
          id="email"
          name="email"
          defaultValue={userEmail}
          maxLength={50}
          readOnly
          // required
        />
      </div>

      <div className="flex flex-col justify-center items-center w-full my-2 mx-auto">
        <label
          htmlFor="password"
          className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
        >
          Password{" "}
          <svg
            width="7"
            height="7"
            className="ml-1"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
              fill="#EF4444"
            />
          </svg>
        </label>
        <input
          className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
          type="text"
          placeholder="Password"
          title="Insert password"
          id="password"
          name="password"
          value={password}
          maxLength={50}
          // minLength={6}
          onChange={(e) => setPassword(sanitizeInput(e.target.value))}
          // required
        />
      </div>

      <button
        disabled={resetLoading}
        className="w-32 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-3 flex justify-center items-center gap-1 mt-3 disabled:cursor-not-allowed"
      >
        Reset
      </button>
    </form>
  );
};

export default ResetPasswordForm;
