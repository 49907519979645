import React, { createContext, useCallback, useReducer } from 'react';
import api from '../../config/api';
import resetReducer from './ResetReducer';

const ResetContext = createContext();
export const ResetLoadingContext = createContext();
export const ResetErrorContext = createContext();
export const ResetSuccessContext = createContext();
export const ResetMessageContext = createContext();

export const ResetProvider = ({ children }) => {

    // INITIAL STATE
    const initialState = {
        resetLoading: false,
        resetError: '',
        resetSuccess: false,
        resetMessage: ''
    };

    // REDUCER
    const [state, dispatch]  = useReducer(resetReducer, initialState);

    // LOGIN
    const resetFn = useCallback(async(email, password)=>{

        try{
            // console.log(email, password);
            // DISPATCH LOADING
            dispatch({type: 'RESET_LOADING', loading:true});
            
             // Config
             let config ={
                headers:{
                    'Content-Type':'application/json',
                    // Authorizatin:`Bearer ${tkn}`
                }
            }
            // FETCH POST
            const res = await api.post('/auth/reset', {
                email,
                password,
            },config);
            if(res?.status === 200){
                //  console.log(res.data)
                
                // DISPATCH SUCCESS
                dispatch({type: 'RESET_SUCCESS', resetSuccess: res?.data?.success});

                dispatch({type: 'RESET_MESSAGE', resetMessage: res?.data?.message});
                
            }

        }catch(err){
            // console.log(err);
            if (err?.response) {
                // console.log(err.response);
                //  console.log(err.response.data);
                //  console.log(err?.response?.data?.message)
                // console.log(err.response.status);
                // console.log(err.response.headers);
                dispatch({type: 'RESET_ERROR', error: err?.response?.data?.message});
            } else {
                console.log(err?.message);
                dispatch({type: 'RESET_ERROR', error: err?.message});
            }
        }
        finally{
            dispatch({type: 'RESET_LOADING', loading:false});
            setTimeout(()=>{
                dispatch({type: 'RESET_SUCCESS', resetSuccess: false});
                dispatch({type: 'RESET_ERROR', error: ''});
                dispatch({type:'RESET_MESSAGE', resetMessage:''})
            }, 3000);
        };
    },[]);

    // RETURN 
    return(
        <ResetContext.Provider value={resetFn}>
            <ResetLoadingContext.Provider value={state.resetLoading}>
                <ResetErrorContext.Provider value={state.resetError}>
                    <ResetSuccessContext.Provider value={state.resetSuccess}>
                        <ResetMessageContext.Provider value={state.resetMessage}>
                            {children}
                        </ResetMessageContext.Provider>
                    </ResetSuccessContext.Provider>
                </ResetErrorContext.Provider>
            </ResetLoadingContext.Provider>
        </ResetContext.Provider>
    )
}

export default ResetContext;
