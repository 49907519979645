import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { toast } from "react-toastify";
import GenerateContext, {
  GenerateLengthContext,
  GenerateSetLengthContext,
  GenerateUpperContext,
  GenerateSetUpperContext,
  GenerateNumContext,
  GenerateSetNumContext,
  GenerateSymContext,
  GenerateSetSymContext,
  GenerateDataContext,
  GenerateIsDisabled,
  GenerateErrorMessageContext,
  GenerateMessageContext
} from "../context/GeneratePswContextReducer/GeneratePswContext";
import Spinner from "./ui/loaders/Spinner";

const Random = () => {

  // CONTEXT
  const usrIn = useContext(UserInContext);
  // GENERATE CONTEXT
  const pswLength = useContext(GenerateLengthContext);
  const setPswLength = useContext(GenerateSetLengthContext);
  const upper = useContext(GenerateUpperContext);
  const setUpper = useContext(GenerateSetUpperContext);
  const num = useContext(GenerateNumContext);
  const setNum = useContext(GenerateSetNumContext);
  const sym = useContext(GenerateSymContext);
  const setSym = useContext(GenerateSetSymContext);
  const genPswFn = useContext(GenerateContext);
  const generated = useContext(GenerateDataContext);
  const genIsDisabled = useContext(GenerateIsDisabled);
  const error = useContext(GenerateErrorMessageContext)
  const message = useContext(GenerateMessageContext)
  
  // ERROR
  useEffect(() => {
    if (error !== "") {
      toast.error(error);
    }
  }, [error]);

  // MESSAGE
  useEffect(() => {
    if (message !== "") {
      console.log(message)
      toast.success(message);
    }
  }, [message]);

  // HISTORY
  const history = useNavigate();

  // UseEffect
  useEffect(() => {
    if (usrIn === null) {
      history("/");
    }
  }, [usrIn, history]);

  const handlePasswordLength = useCallback((e) => {
      let passLength = e.target.value;
      // const numericValue  = Math.min(Math.max(parseInt(passLength, 10) || 0, 8), 33); // Clamp the value between 8 and 33
      const numericValue  = parseInt(passLength, 10);

      setPswLength(numericValue);

  }, [ setPswLength]);

  useEffect(() => {
   let timeOut = setTimeout(() => {
        if(pswLength < 8){
          toast.error("Password length must be at least 8 characters");
          setPswLength(8);
        }else if (pswLength > 33){
          toast.error("Password length must be at most 33 characters");
          setPswLength(33);
        }
    },1500);

    return () => {
      clearTimeout(timeOut);
    }
  },[pswLength,setPswLength]);



  // RETURN
  return (
    <>
      <div className="w-full flex flex-col justify-start items-center flex-1 p-2 mt-4">
        <div className="w-full max-w-[320px] h-auto flex flex-col justify-center items-center mx-auto">
          <h1 className="text-2xl md:text-3xl font-semibold my-2">
            Password Generator
          </h1>

          <div className="w-full flex justify-center my-3">
            {genIsDisabled && <Spinner />}
          </div>

          <div className="w-full">
            <label
              htmlFor="password"
              className="flex items-start pl-3 w-full text-gray-600 dark:text-gray-300 text-sm font-medium"
            ></label>
            <input
              type="text"
              className="block w-full h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs dark:text-gray-900  dark:bg-gray-300 bg-gray-600 text-white  border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none my-2"
              value={generated}
              readOnly
            />
          </div>

          <div className="w-full flex justify-center items-center flex-col">
            <div className="w-full flex  justify-between items-center mb-2">
              <label
                htmlFor="length"
                className="flex items-start pl-3 w-full text-gray-600 dark:text-gray-300 text-sm font-medium"
              >
                Length{" "}
                <svg
                  width="7"
                  height="7"
                  className="ml-1"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                    fill="#EF4444"
                  />
                </svg>
              </label>
              <input
                type="number"
                id="length"
                name="length"
                min={8}
                max={33}
                className="max-w-4 w-full  grid place-items-center h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none my-2"
                value={pswLength}
                onChange={handlePasswordLength}
              />
            </div>

            <div className="w-full flex justify-center items-center mb-2">
              <label
                htmlFor="upper"
                className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
              >
                Upper Case{" "}
                <svg
                  width="7"
                  height="7"
                  className="ml-1"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                    fill="#EF4444"
                  />
                </svg>
              </label>
              <input
                className="w-5 h-5 cursor-pointer  border border-gray-300  rounded-md mr-2 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                type="checkbox"
                id="upper"
                name="upper"
                value={upper}
                checked={upper}
                onChange={(e) => setUpper(e.target.checked)}
              />
            </div>
            <div className="w-full flex justify-center items-center mb-2">
              <label
                htmlFor="num"
                className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
              >
                Num{" "}
                <svg
                  width="7"
                  height="7"
                  className="ml-1"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                    fill="#EF4444"
                  />
                </svg>
              </label>
              <input
                className="w-5 h-5 cursor-pointer  border border-gray-300  rounded-md mr-2 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                type="checkbox"
                id="num"
                name="num"
                value={num}
                checked={num}
                onChange={(e) => setNum(e.target.checked)}
              />
            </div>
            <div className="w-full flex justify-center items-center mb-2">
              <label
                htmlFor="sym"
                className="flex items-start pl-3 w-full mb-2 text-gray-600 dark:text-gray-300 text-sm font-medium"
              >
                Symbols{" "}
                <svg
                  width="7"
                  height="7"
                  className="ml-1"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
                    fill="#EF4444"
                  />
                </svg>
              </label>
              <input
                className="w-5 h-5 cursor-pointer  border border-gray-300  rounded-md mr-2 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100"
                type="checkbox"
                id="sym"
                name="sym"
                value={sym}
                checked={sym}
                onChange={(e) => setSym(e.target.checked)}
              />
            </div>
            <div className="w-full flex justify-center items-center mt-2">
              <button
                type="button"
                title="Generate Password"
                disabled={genIsDisabled}
                onClick={genPswFn}
                className="w-32 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-3  flex justify-center items-center gap-1 mt-3"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Random;
