import React from "react";
import Spinner from "../loaders/Spinner";
import sanitizeInput from "../../../hooks/sanitizeInput";

const LoginForm = ({
  formRef,
  handleSubmit,
  firstLoginLoading,
  email,
  setEmail,
  password,
  setPassword,
  disabled,
}) => {
  return (
    <form
      autoComplete="off"
      className="relative w-full flex flex-col justify-center items-center"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <div className="text-center text-lg my-4">
        {firstLoginLoading && <Spinner />}
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-6">
        <div className="relative w-full flex flex-col justify-center items-center">
          {/* <label htmlFor="email">Email</label> */}
          <input
            className="block w-full max-w-72 h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none "
            autoFocus
            type="email"
            placeholder=""
            title="Insert value"
            id="email"
            name="email"
            value={email}
            maxLength={50}
            minLength={6}
            onChange={(e) => setEmail(sanitizeInput(e.target.value))}
            // required
          />
        </div>

        <div className="relative w-full flex flex-col justify-center items-center mb-2">
          {/* <label htmlFor="password">Password</label> */}
          <input
            className="w-full max-w-72 block h-11 px-5 py-2.5 leading-7 text-base font-normal shadow-xs text-gray-900 dark:text-white bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none"
            type="password"
            placeholder=""
            title="Insert value"
            id="password"
            name="password"
            value={password}
            maxLength={50}
            // minLength={6}
            onChange={(e) => setPassword(sanitizeInput(e.target.value))}
            // required
          />
        </div>

        <button
          type="submit"
          disabled={disabled}
          className="outline-none duration-300 text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-offset-1 focus:ring-purple-300 font-bold rounded-full text-lg w-full sm:w-auto px-5 py-2.5 text-center disabled:cursor-not-allowed disabled:opacity-50"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
