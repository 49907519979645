import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tranquiluxe } from "uvcanvas";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";

const About = () => {
  const usrIn = useContext(UserInContext);
  // HISTORY
  const history = useNavigate();

  useEffect(() => {
    if (!usrIn) {
      history("/");
    }
  }, [usrIn, history]);

  // RETURN
  return (
    <div className="w-full min-h-100 flex flex-col justify-center items-center flex-1 relative rounded-lg">
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center blend-multiply">
        <h1 className="text-2xl md:text-4xl font-bold mb-2">About</h1>
        <p className="text-md leading-6">We are creators</p>
        <div className="w-[260px] h-auto relative z-20">
          <img className="w-full object-contain" src="/imgs/about-2.svg" alt="about" />
        </div>
      </div>
      <div className="w-full h-full opacity-20">
        <Tranquiluxe />
      </div>
    </div>
  );
};

export default About;
