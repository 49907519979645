import React, { useCallback, useState } from "react";
import "./UploadBckForm.css";
import api from "../../../config/api";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";


const UploadBckForm = ( {fetchAllPsws}) => {
  const [file, setFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // GET COOKIE
  // const cookies = new Cookies();

  // HANDLE CHANGE
  const handleChange = useCallback(
    (e) => {
      // console.log(e.target.files[0]);
      setFile(e.target.files[0]);
    },
    [setFile]
  );

  // HANDLE SUBMIT
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setIsDisabled(true);
        // GET COOKIE
        const cookies = new Cookies();

        // TKN
        let tkn = cookies.get("tkn");

        if (!tkn) {
          return;
        }

        // If no file return
        if (!file) {
          toast.error(`Please select a file to upload`);
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tkn}`,
          },
        };
        // console.log(formData);
        let res = await api.post(`/bck/upload`, formData, config);
        // console.log(res)

        if (res.data.success) {
          toast.success(res.data.message);
          (async () => {
            await fetchAllPsws();  
          })();
          return;
        }

        toast.error(`Something wrong`);
      } catch (e) {
        console.log(e);
        toast.error(e.message || `Something wrong`);
      } finally {
        setIsDisabled(false);
      }
    },
    [file, setIsDisabled, fetchAllPsws]
  );

  // RETURN
  return (
    <form
      className="Uploadbck relative flex flex-col justify-center items-center gap-2"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <input
        type="file"
        className="upload-input-file"
        name="file"
        id="file"
        accept=".json"
        onChange={handleChange}
      />
      <button type="submit" disabled={isDisabled} className="upload-btn-submit">
        Upload
      </button>
    </form>
  );
};

export default UploadBckForm;
