import React from "react";
import QRCode from "react-qr-code";
import { Link, useNavigate } from "react-router-dom";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";

const Profile = () => {
  // USE CONTEXT
  const usrIn = React.useContext(UserInContext);

  const history = useNavigate();

  React.useEffect(() => {
    if (!usrIn) {
      history("/");
    }
  }, [history, usrIn]);

  // RETURN
  return (
    <div className="w-full flex flex-col justify-center items-center flex-1">
      <h1 className="text-2xl font-bold">{usrIn && usrIn?.username} QRCode</h1>

      <h4 className="text-xs my-2">
        Keep the code secret, scan the qrcode with your auth app
      </h4>

      <div className="flex flex-col justify-center items-center w-full p-2">
        <div className="w-full">
          {!usrIn === "" ? (
            <img src="/qr.placeholder.png" alt="qrcode" />
          ) : (
            <QRCode
              size={200}
              style={{
                height: "auto",
                maxWidth: "300px",
                width: "100%",
                margin: "20px auto",
              }}
              value={usrIn && usrIn?.otpauthurl}
              viewBox={`0 0 200 200`}
            />
          )}
        </div>
        <details className="w-full max-w-72 text-balance">
          <summary className="text-left cursor-pointer">Secret Key</summary>
          <small className="text-[9px]">{usrIn && usrIn?.secret}</small>
        </details>

        <div className="profile-reset-wrapper">
          <Link
            className="w-52 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-3 flex justify-center items-center gap-1 mt-3 disabled:cursor-not-allowed"
            to={`/auth/reset/${usrIn?.email}`}
          >
            Reset Password
          </Link>
        </div>

        <div className="w-full grid place-items-center text-center">
          <Link
            to="/"
            className="w-52 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-3 flex justify-center items-center gap-1 mt-3 disabled:cursor-not-allowed"
          >
            Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Profile;
