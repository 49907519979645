import { useParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import PswsGetAllContext, {PswsGetAllLoading} from '../context/PswsGetAllContext';
import PswGetSingleContext, {
  PswGetSingleData,
  PswGetSingleLoading,
  PswGetSingleError,
  PswGetSingleReset,
} from "../context/PswGetSingleContextReducer/PswGetSingleContext";
import PswsGetPageContext from "../context/PswsGetPageContextReducer/PswsGetPageContext";
import PswUpdateContext, {
  PswUpdateLoading,
  PswUpdateData,
} from "../context/PswUpdateContextReducer/PswUpdateContext";
import PswDeleteContext, {
  PswDeleteLoading,
  PswDeleteError,
  PswDeleteSuccess,
} from "../context/PswDeleteContextReducer/PswDeleteContext";
import PswGetPswContext, {
  PswGetPswData,
  PswGetPswLoading,
  PswGetPswError,
} from "../context/PswGetPswContextReducer/PswGetPswContext";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { toast } from "react-toastify";
import Spinner from "./ui/loaders/Spinner";
import PswPageForm from "./ui/forms/PswPageForm";

const PswPage = () => {
  // ID
  const { id } = useParams();
  // console.log(id);

  // HISTORY
  const history = useNavigate();

  // VALUES
  const [values, setValues] = useState({
    site: "",
    user: "",
    psw: "",
  });

  // CONSTE SINGLE PSW
  const pswFetchSingleFn = useContext(PswGetSingleContext);
  const pswGetSingleLoading = useContext(PswGetSingleLoading);
  const pswGetSingleData = useContext(PswGetSingleData);
  const pswGetSingleError = useContext(PswGetSingleError);
  const pswGetSingleReset = useContext(PswGetSingleReset);

  // LOGOUT CONTEXT
  const usrIn = useContext(UserInContext);

  // USE EFFECT CHECK USER
  useEffect(() => {
    if (usrIn === null) {
      history("/");
    }
  }, [usrIn, history]);

  // FETCH SINCLE ERROR USE EFFECT
  useEffect(() => {
    if (pswGetSingleError) {
      toast.error(pswGetSingleError);
      history("/");
    }
  }, [pswGetSingleError, history]);

  // CONTEXT PSWS GET PAGE
  const fetchPsws = useContext(PswsGetPageContext);

  // FETCH GET PASSWORD
  const pswFetch = useContext(PswGetPswContext);
  const pswGetPswLoading = useContext(PswGetPswLoading);
  const pswGetPswError = useContext(PswGetPswError);
  const pswGetPswData = useContext(PswGetPswData);

  // PSW CONTEXT UPDATE
  const pswUpdateFn = useContext(PswUpdateContext);
  const pswUpdateLoading = useContext(PswUpdateLoading);
  const pswUpdateData = useContext(PswUpdateData);

  // PSW CONTEXT DELETE
  const pswDeleteFn = useContext(PswDeleteContext);
  const pswDeleteLoading = useContext(PswDeleteLoading);
  const pswDeleteError = useContext(PswDeleteError);
  const pswDeleteSuccess = useContext(PswDeleteSuccess);

  // CONTEXT PSWS GET ALL
  //  const fetchAllPswsFn =useContext(PswsGetAllContext);
  // const pswsGetAllLoading =useContext(PswsGetAllLoading);

  // USE EFFECT UPDATE PSW
  useEffect(() => {
    if (pswUpdateData) {
      toast.success(pswUpdateData?.message);
    }
  }, [pswUpdateData]);

  // USE EFFECT FETCH
  useEffect(() => {
    if (usrIn !== null) {
      // RESET
      pswGetSingleReset();
      // FETCH FRESH DATA ON LOAD
      (async () => await pswFetchSingleFn(id))();
    }

    // eslint-disable-next-line
  }, [id, usrIn]);

  //* USE EFFECT ADD DATA
  useEffect(() => {
    if (id && pswGetSingleData) {
      setValues({
        site: pswGetSingleData.site,
        user: pswGetSingleData.user,
        psw: pswGetSingleData.psw,
      });
    }
    // eslint-disable-next-line
  }, [pswGetSingleData]);

  // HANDLE EDIT
  const handleEdit = useCallback(async () => {
    // console.log(id, values);
    let sure = window.confirm("Are you sure?");

    if (sure) {
      // console.log(values)
      let res = await pswUpdateFn(id, values);
      if (res) {
        // console.log(res);
        await pswFetchSingleFn(id);
        await fetchPsws();
        toast.success(res.message);
      }
    }
  }, [pswUpdateFn, fetchPsws, pswFetchSingleFn, id, values]);

  //* HANDLE DELETE
  const handleDelete = useCallback(async () => {
    // console.log(p.id);
    let sure = window.confirm("Are you sure?");
    if (sure) {
      await pswDeleteFn(id);
    }
  }, [pswDeleteFn, id]);

  // DELETE SUCCESS
  useEffect(() => {
    if (pswDeleteSuccess !== "") {
      // console.log(pswDeleteSuccess);
      toast.success(pswDeleteSuccess);
      // RESET SINGLE STATE
      pswGetSingleReset();
      // FETCH ALL PSWS
      (async () => await fetchPsws())();
      // REDIRECT
      history("/");
    }
  }, [pswDeleteSuccess, fetchPsws, pswGetSingleReset, history]);

  // DELETE ERROR
  useEffect(() => {
    if (pswDeleteError) {
      toast.error(pswDeleteError);
    }
  }, [pswDeleteError]);

  // HANDLE UNLOCK PASSWORD
  const handleUnLock = useCallback(
    async (id) => {
      // console.log(id);
      let sure = window.confirm("Are you sure?");
      if (sure) {
        await pswFetch(id);
      }
    },
    [pswFetch]
  );

  //* USE EFFECT COPY PASSWORD TO THE CLIPBOARD
  useEffect(() => {
    let timePsw;
    if (pswGetPswData !== "") {
      // console.log(pswGetPswData);
      // console.log('Password copied');
      toast.success("Password copied.");

      navigator.clipboard.writeText(pswGetPswData);

      setValues({ ...values, psw: pswGetPswData });

      timePsw = setTimeout(() => {
        setValues({ ...values, psw: "" });
      }, 10000);
    }
    return () => clearTimeout(timePsw);
    // eslint-disable-next-line
  }, [pswGetPswData]);

  // PSW GET ERROR
  useEffect(() => {
    if (pswGetPswError) {
      toast.error(pswGetPswError);
    }
  }, [pswGetPswError]);

  // COPY USER
  const copyUser = useCallback(async () => {
    navigator.clipboard.writeText(values.user);
  }, [values.user]);
  // COPY SITE
  const copySite = useCallback(async () => {
    navigator.clipboard.writeText(values.site);
  }, [values.site]);

  // RETURN
  return (
    <main className="w-full flex flex-col justify-start items-center flex-1">
      {pswGetSingleLoading && !pswGetSingleData ? (
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          <div className="w-full">
            <h1 className="text-2xl md:text-3xl font-bold">Edit</h1>
            {
              <div className="text-center w-full flex justify-center items-center mt-3 min-h-6">
                {pswGetPswLoading && <Spinner />}
              </div>
            }

            <PswPageForm
              values={values}
              setValues={setValues}
              copySite={copySite}
              copyUser={copyUser}
            />

            <div className="flex justify-around items-center w-full p-2">
              <div title="Home" className="grid place-items-center t">
                <Link onClick={async () => await fetchPsws()} to={`/`}>
                  <svg
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z" />
                    <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21" />
                  </svg>
                </Link>
              </div>

              <div
                title="Update"
                className="grid place-items-center"
                onClick={handleEdit}
              >
                {pswUpdateLoading ? (
                  <span className="grid place-items-center">
                    <Spinner />
                  </span>
                ) : (
                  <button className="w-32 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-3  flex justify-center items-center gap-1">
                    Update{" "}
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20.5 8c-1.392-3.179-4.823-5-8.522-5C7.299 3 3.453 6.552 3 11.1" />
                      <path d="M16.489 8.4h3.97A.54.54 0 0 0 21 7.86V3.9M3.5 16c1.392 3.179 4.823 5 8.522 5 4.679 0 8.525-3.552 8.978-8.1" />
                      <path d="M7.511 15.6h-3.97a.54.54 0 0 0-.541.54v3.96" />
                    </svg>
                  </button>
                )}
              </div>

              <div
                title="Unlock"
                className="grid place-items-center"
                onClick={() => handleUnLock(id)}
              >
                {pswGetPswLoading ? (
                  <span className="grid place-items-center">
                    <Spinner />
                  </span>
                ) : (
                  <button className="w-32 h-12 bg-indigo-600 hover:bg-indigo-800 transition-all duration-300 rounded-full shadow-xs dark:shadow-gray-100 text-white text-base font-semibold leading-6  flex justify-center items-center gap-1">
                    Unloack{" "}
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 10V8c0-2.761 1.239-5 4-5 2.094 0 3.313 1.288 3.78 3.114M3.5 17.8v-4.6c0-1.12 0-1.68.218-2.107a2 2 0 0 1 .874-.875c.428-.217.988-.217 2.108-.217h10.6c1.12 0 1.68 0 2.108.217a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.98 21 18.42 21 17.3 21H6.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3.5 19.481 3.5 18.921 3.5 17.8M16 14v3" />
                    </svg>
                  </button>
                )}
              </div>

              <div
                title="Delete"
                className="grid place-items-center text-red-500"
                onClick={handleDelete}
              >
                {pswDeleteLoading ? (
                  <span className="grid place-items-center">
                    <Spinner />
                  </span>
                ) : (
                  <svg
                    className="cursor-pointer"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m18 9-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0 .386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default PswPage;
