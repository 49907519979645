import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Psw = ({ p }) => {
  // console.log(typeof p);
  const [values, setValues] = useState({
    site: "",
    user: "",
    psw: "",
  });

  // USE EFFECT
  useEffect(() => {
    if (p) {
      setValues({
        site: p.site,
        user: p.user,
        psw: "",
      });
    }

    // eslint-disable-next-line
  }, []);

  // RETURN
  return (
    <li className="py-3 my-3 px-1 rounded-md sm:py-4 shadow-md dark:shadow-sm dark:shadow-slate-300">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <div className="relative w-10 h-10 bg-purple-100 flex justify-center items-center rounded-full">
            <span className="text-purple-600 text-base font-medium capitalize">
              {values.site[0]} {values.user[0]}
            </span>
            <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-gray-300 border-2 border-white rounded-full"></span>
          </div>
        </div>
        <div className="flex-1 min-w-0 ms-4">
          <p className="text-sm font-medium text-gray-900  truncate dark:text-white">
            {values.site}
          </p>
          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
            {values.user}
          </p>
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
          <Link to={`/pswpage/${p.id}`}>
            <button className="py-2.5 px-6 text-sm bg-indigo-500 text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-300 hover:bg-indigo-700 flex items-center gap-0.5">
              Show{" "}
              <svg
                className="hover:translate-x-1 duration-300"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.5 12h15m0 0-5.625-6m5.625 6-5.625 6" />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </li>
  );
};

export default memo(Psw);
