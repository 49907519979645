
const darkReducer = (state, action)=>{

    switch (action.type){
        case 'SET_DARK':
            document.documentElement.setAttribute('data-theme', 'dark');
            // Add html class dark
            document.documentElement.classList.add('dark');
            localStorage.setItem('dark', JSON.stringify(true));
            return  {
                ...state, isDark: true
            };
        case 'SET_LIGHT':
            document.documentElement.setAttribute('data-theme', 'light');
            // Remove html class dark
            document.documentElement.classList.remove('dark');
            localStorage.setItem('dark', JSON.stringify(false));
            return  {
                ...state, isDark: false
            };
        default:
            return state;
    }
};
export default darkReducer;