import React from 'react';
import Feed from './Feed';

const Home = ({ pswsGetPageData }) => {

  // RETURN
  return (
    <main className='relative w-full flex flex-col justify-start items-center overflow-y-auto p-6 flex-1 z-10'>
      <Feed pswsGetPageData={pswsGetPageData} />
    </main>
  )
}

export default Home;
