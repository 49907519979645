import React, { useEffect, useState } from 'react';
import OtpInput from './OtpInput';

const OtpWrapper = ({ setOtp }) => {

    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const [otp5, setOtp5] = useState('');
    const [otp6, setOtp6] = useState('');
    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);
    const [active5, setActive5] = useState(false);
    const [active6, setActive6] = useState(false);

    // Use effect 1
    useEffect(() => {
        if (otp1 === '') {
            // setActive1(true);
        } else {
            setActive1(false);
            setOtp(`${otp1}`);
            setActive2(true);
        }
    }, [setOtp, otp1]);

    // Use effect
    useEffect(() => {
        if (otp2 !== '') {
            setActive2(false);
            setOtp(`${otp1}${otp2}`);
            setActive3(true);
        }
    }, [setOtp, otp1, otp2]);

    // Use effect
    useEffect(() => {
        if (otp3 !== '') {
            setActive3(false);
            setOtp(`${otp1}${otp2}${otp3}`);
            setActive4(true);
        }
    }, [setOtp, otp1, otp2, otp3]);

    // Use effect
    useEffect(() => {
        if (otp4 !== '') {
            setActive4(false);
            setOtp(`${otp1}${otp2}${otp3}${otp4}`);
            setActive5(true);
        }
    }, [setOtp, otp1, otp2, otp3, otp4]);

    // Use effect
    useEffect(() => {
        if (otp5 !== '') {
            setActive5(false);
            setOtp(`${otp1}${otp2}${otp3}${otp4}${otp5}`);
            setActive6(true);
        }
    }, [setOtp, otp1, otp2, otp3, otp4, otp5]);
    // Use effect
    useEffect(() => {
        if (otp6 !== '') {
            setOtp(`${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`);
            setOtp1('');
            setOtp2('');
            setOtp3('');
            setOtp4('');
            setOtp5('');
            setOtp6('');
            setActive6(false);
            setActive1(true);
        }
    }, [setOtp, otp1, otp2, otp3, otp4, otp5, otp6]);



    // RETURN
    return (
        <div className='w-full flex justify-center items-center gap-3'>
            <OtpInput
                value={otp1}
                onChange={setOtp1}
                active={active1}
            />
            <OtpInput
                value={otp2}
                onChange={setOtp2}
                active={active2}
            />
            <OtpInput
                value={otp3}
                onChange={setOtp3}
                active={active3}
            />
            -
            <OtpInput
                value={otp4}
                onChange={setOtp4}
                active={active4}
            />
            <OtpInput
                value={otp5}
                onChange={setOtp5}
                active={active5}
            />
            <OtpInput
                value={otp6}
                onChange={setOtp6}
                active={active6}
            />
        </div>
    )
}

export default OtpWrapper;