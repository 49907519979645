import React, { useEffect, useRef } from 'react';

const OtpInput = ({ value, onChange, active }) => {

    // Ref
    const ref = useRef(null);

    useEffect(() => {
        if (ref && ref.current) {
            if (active) {
                ref.current.focus()
            }
            else {
                ref.current.blur()
            }
        }
    }, [active]);

    // RETURN
    return (
        <div className='flex w-full max-w-14 gap-2'>
            <input
                ref={ref}
                type="tel"
                inputMode="numeric"
                autoComplete='one-time-code'
                pattern='\d{1}'
                maxLength={1}
                className='w-full h-14 border border-gray-300 rounded-lg text-center text-2xl focus:outline-purple-500 duration-300'
                value={value}
                placeholder={'0'}
                onChange={e => onChange(e.target.value)}
                autoFocus={active}
            />
        </div>
    )
};

export default OtpInput;