import React, { useCallback } from "react";
import Psw from "./Psw";

import DarkContext from "../context/DarkContextReducer/DarkContext";
import { SearchKey } from "../context/SearchContextReducer/SearchContext";

import {
  PswsGetPagePage,
  PswsGetPageLastPage,
  PswsGetPageSetPage,
} from "../context/PswsGetPageContextReducer/PswsGetPageContext";
import Pagination from "./Pagination";

const Feed = ({ pswsGetPageData }) => {
  // CONTEXT PSWS GET PAGE
  const page = React.useContext(PswsGetPagePage);
  const lastPage = React.useContext(PswsGetPageLastPage);
  const pswsGetPageSetPageFn = React.useContext(PswsGetPageSetPage);

  // PAGES CUT COUNT, HOW MANY PAGES NUMBER TO DISPLAY
  const pagesCutCount = 6;

  // CONTEXT SEARCH
  const search = React.useContext(SearchKey);

  // CONTEXT DARK
  const isDark = React.useContext(DarkContext);

  // HANDLE FIRST PAGE
  const handleFirstPage = useCallback(() => {
    pswsGetPageSetPageFn(1);
  }, [pswsGetPageSetPageFn]);

  // HANDLE PREV
  const handlePrev = useCallback(() => {
    pswsGetPageSetPageFn(page - 1);
  }, [page, pswsGetPageSetPageFn]);

  // HANDLE LAST PAGE
  const handleLastPage = useCallback(() => {
    pswsGetPageSetPageFn(+lastPage);
  }, [lastPage, pswsGetPageSetPageFn]);

  // HANDLE NEXT
  const handleNext = useCallback(() => {
    pswsGetPageSetPageFn(page + 1);
  }, [page, pswsGetPageSetPageFn]);

  const handlePagNumb = useCallback(
    (num) => {
      pswsGetPageSetPageFn(num);
    },
    [pswsGetPageSetPageFn]
  );

  // RANGE FUNCTION
  const range = useCallback((start, end) => {
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  }, []);

  // HANDLE PAGE CUT
  const getPagesCut = useCallback(({ lastPage, pagesCutCount, page }) => {
    const ceiling = Math.ceil(pagesCutCount / 2);
    const floor = Math.floor(pagesCutCount / 2);

    if (+lastPage < +pagesCutCount) {
      return { start: 1, end: +lastPage };
    } else if (+page >= 1 && +page <= +ceiling) {
      return { start: 1, end: pagesCutCount };
    } else if (+page + +floor >= +lastPage) {
      return { start: +lastPage - +pagesCutCount, end: +lastPage };
    } else {
      return { start: +page - +ceiling, end: +page + +floor };
    }
  }, []);

  // PAGES
  const pagesCut = getPagesCut({ lastPage, pagesCutCount, page });
  const pagesRange = range(pagesCut.start, pagesCut.end);

  // RETURN
  return (
    <div className="w-full h-full flex-1 flex flex-col overflow-hidden">
      <div className="w-full p-2 rounded-lg">
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {pswsGetPageData && (
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Latest Customers
                  </h5>
                </div>
              ) &&
              pswsGetPageData.map((p) => <Psw key={p.id} p={p} />)}
          </ul>
        </div>
      </div>
      {pswsGetPageData.length === 0 && (
        <div
          className={`${
            isDark ? "landing" : "landing isDark"
          } relative block flex-1 w-full h-full justify-center items-center`}
        >
          <img
            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-xl object-contain object-center ob"
            src="/imgs/front.png"
            alt="Front"
          />
        </div>
      )}

      {/* PAGINATION  */}
      <Pagination
        page={page}
        lastPage={lastPage}
        pagesRange={pagesRange}
        handlePagNumb={handlePagNumb}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
        search={search}
      />
    </div>
  );
};

export default Feed;
