import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import LogoutContext, {
  UserInContext,
} from "../context/LogoutContextReducer/LogoutContext";
import SearchContext, {
  SearchKey,
  SearchSetKey,
  SearchLoading,
  SearchError,
} from "../context/SearchContextReducer/SearchContext";
import PswsGetPageContext from "../context/PswsGetPageContextReducer/PswsGetPageContext";
import Cookies from "universal-cookie";
import sanitizeInput from "../hooks/sanitizeInput";
import { toast } from "react-toastify";
import Spinner from "./ui/loaders/Spinner";

const Nav = () => {
  // LOCATION
  const location = useLocation();

  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT SEARCH
  const searchFn = useContext(SearchContext);
  const search = useContext(SearchKey);
  const setSearch = useContext(SearchSetKey);
  const searchLoading = useContext(SearchLoading);
  const searchError = useContext(SearchError);
  //   console.log(searchLoading);

  // CONTEXT PSWS GET PAGE
  const fetchPsws = useContext(PswsGetPageContext);

  // USER IN
  const usrIn = useContext(UserInContext);
  // LOGOUT CONTEXT
  const logoutFn = useContext(LogoutContext);

  // HANDLE SEARCH
  const handleSearch = useCallback(
    (e) => {
      setSearch(sanitizeInput(e.target.value));
    },
    [setSearch]
  );

  // COOKIE HOOK
  const cookies = useMemo(() => new Cookies(), []);

  // HANDLE LOGOUT
  const handleLogout = useCallback(async () => {
    try {
      cookies.remove("tkn", { path: "/" });
      await logoutFn();
    } catch (err) {
      toast.error(err);
    }
  }, [logoutFn, cookies]);

  // LOGIN
  useEffect(() => {
    if (usrIn === null) {
      setSearch("");
    }
  }, [usrIn, setSearch]);

  // SEARCH ERROR
  useEffect(() => {
    if (searchError !== "") {
      toast.error(searchError);
    }
  }, [searchError]);

  // HANDLE SEARCH SUBMIT
  const handleSearchSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (search === "") {
        toast.error("Please enter a search key");
        return;
      }

      await searchFn(search);

      // Redirect to search page
      if (location.pathname !== "/search") {
        navigate("/search");
      }
      // RESET INPUT FIELD
      setSearch("");
    },
    [search, searchFn, setSearch, navigate, location.pathname]
  );

  // RETURN
  return (
    <nav className="w-full flex justify-center items-center my-2">
      <div className="min-h-9">
        {usrIn !== null && (
          <form
            title="Search"
            className="p-1 flex justify-center items-center"
            onSubmit={handleSearchSubmit}
          >
            {/* <label htmlFor="search">Search Post</label> */}
            <input
              className="font-['TitilliumWeb'] outline-none border border-gray-300 rounded-full py-2 px-4 text-gray-700 dark:text-white leading-tight focus:outline-none focus:border-gray-500"
              id="search"
              name="search"
              placeholder="Search"
              type="text"
              value={search}
              maxLength={50}
              minLength={2}
              onChange={(e) => handleSearch(e)}
              required
            />

            <button
              className="cursor-pointer border-none outline-none bg-transparent  grid place-items-center gap-1 hover:scale-105 duration-200"
              type="submit"
              disabled={searchLoading}
            >
              {searchLoading ? (
                <div className="ml-1">
                  {" "}
                  <Spinner />{" "}
                </div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-7 h-7 ml-1 mr-4 duration-200 hover:scale-110"
                  width="64"
                  height="64"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="10" cy="10" r="7" />
                  <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
              )}
            </button>
          </form>
        )}
      </div>

      <div className="">
        <ul className="list-none flex justify-around items-center gap-3">
          {usrIn && (
            <>
              <li>
                <Link
                  title="Home"
                  onClick={async () => await fetchPsws()}
                  className="grid place-items-center"
                  to="/"
                >
                  <svg
                    className="w-7 h-7 duration-200 hover:scale-110"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z" />
                    <path d="M9 16c.85.63 1.885 1 3 1s2.15-.37 3-1m-5.5-4.5V11m5 .5V11" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  title="About"
                  className="grid place-items-center"
                  to="/about"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-7 h-7 duration-200 hover:scale-110"
                    width="64"
                    height="64"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                    <polyline points="11 12 12 12 12 16 13 16" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  title="Add"
                  className="grid place-items-center"
                  to="/newpsw"
                >
                  <svg
                    className="w-7 h-7 duration-200 hover:scale-110"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 128"
                    width="512px"
                    height="512px"
                  >
                    {" "}
                    <path
                      stroke="currentColor"
                      d="M 64 6.0507812 C 49.15 6.0507812 34.3 11.7 23 23 C 0.4 45.6 0.4 82.4 23 105 C 34.3 116.3 49.2 122 64 122 C 78.8 122 93.7 116.3 105 105 C 127.6 82.4 127.6 45.6 105 23 C 93.7 11.7 78.85 6.0507812 64 6.0507812 z M 64 12 C 77.3 12 90.600781 17.099219 100.80078 27.199219 C 121.00078 47.499219 121.00078 80.500781 100.80078 100.80078 C 80.500781 121.10078 47.500781 121.10078 27.300781 100.80078 C 7.0007813 80.500781 6.9992188 47.499219 27.199219 27.199219 C 37.399219 17.099219 50.7 12 64 12 z M 64 42 C 62.3 42 61 43.3 61 45 L 61 61 L 45 61 C 43.3 61 42 62.3 42 64 C 42 65.7 43.3 67 45 67 L 61 67 L 61 83 C 61 84.7 62.3 86 64 86 C 65.7 86 67 84.7 67 83 L 67 67 L 83 67 C 84.7 67 86 65.7 86 64 C 86 62.3 84.7 61 83 61 L 67 61 L 67 45 C 67 43.3 65.7 42 64 42 z"
                    />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  title="Random Password"
                  className="grid place-items-center"
                  to="/random"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-7 h-7 duration-200 hover:scale-110"
                    width="44"
                    height="44"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="4.5" cy="9.5" r="1" />
                    <circle cx="9.5" cy="4.5" r="1" />
                    <circle cx="9.5" cy="14.5" r="1" />
                    <circle cx="4.5" cy="19.5" r="1" />
                    <circle cx="14.5" cy="9.5" r="1" />
                    <circle cx="19.5" cy="4.5" r="1" />
                    <circle cx="14.5" cy="19.5" r="1" />
                    <circle cx="19.5" cy="14.5" r="1" />
                  </svg>
                </Link>
              </li>

              <li>
                <Link
                  title="Backup"
                  className="grid place-items-center"
                  to="/backup"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-7 h-7 duration-200 hover:scale-110"
                    width="44"
                    height="44"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <ellipse cx="12" cy="6" rx="8" ry="3" />
                    <path d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302 -.267m4.698 -2.733v-6" />
                    <path d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995m8.462 -6.995v-2m-6 7h7m-3 -3l3 3l-3 3" />
                  </svg>
                </Link>
              </li>

              <li>
                <Link
                  title="Logout"
                  to="/"
                  className="grid place-items-center"
                  onClick={handleLogout}
                >
                  <div className="logout-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-7 h-7 duration-200 hover:scale-110"
                      width="64"
                      height="64"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M7 12h14l-3 -3m0 6l3 -3" />
                    </svg>
                  </div>
                </Link>
              </li>
            </>
          )}
          {!usrIn && (
            <li>
              {/* <Link to='/auth/login'>
                            <img src="/imgs/login.svg" alt="in" />
                        </Link> */}

              {/* <Link to='/auth/register'>
                            <img src="/imgs/register.svg" alt="signup" />
                        </Link> */}
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
