"use client";
import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";

const ReCAPTCHAComponent = ({ setDisabled }) => {
  // STATE
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [challengeComplete, setChallengeComplete] = useState(false);
  const [challengeFailed, setChallengeFailed] = useState(false);
  const [challengeSuccess, setChallengeSuccess] = useState(false);

  // Use effect
  useEffect(() => {
    const newNum1 = Math.floor(Math.random() * 9) + 1;
    const newNum2 = Math.floor(Math.random() * 9) + 1;
    setNum1(newNum1);
    setNum2(newNum2);
    setCorrectAnswer(newNum1 + newNum2);
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      setUserInput(event.target.value);
      if (event.target.value === correctAnswer.toString()) {
        toast.success("Sfida superata con successo");
        setTimeout(() => {
          setChallengeComplete(true);
          setDisabled(false);
          setChallengeSuccess(true);
          setTimeout(() => {
            setChallengeSuccess(false);
          }, 5000);
        }, 1000);
      } else {
        setChallengeComplete(false);
        setChallengeFailed(true);
        setDisabled(true);
        setTimeout(() => {
          setUserInput("");
          setChallengeFailed(false);
        }, 5000);
      }
    },
    [correctAnswer, setDisabled]
  );

  // RETURN
  return (
    <div className="max-w-sm mt-4 mx-auto">
      {challengeComplete ? (
        <div className="py-4 w-full flex flex-col justify-center items-center">
          {/* <label
            htmlFor="username-success"
            className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
          >
            Sfida superata con successo
          </label> */}
          <input
            value={userInput}
            readOnly
            type="text"
            id="username-success"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-xs rounded-lg  w-8 p-1.5 dark:bg-gray-700 dark:border-green-500 block text-center"
            placeholder={`${num1} + ${num2} = ${correctAnswer}`}
          />
          <p className="mt-2 text-xs lg:text-sm text-green-600 dark:text-green-500">
            {challengeSuccess && (
              <span className="font-medium">
                🥳 Alright!
              </span>
            )}
          </p>
        </div>
      ) : (
        <div className="py-4 w-full flex flex-col justify-center items-center">
          <label
            htmlFor="username-error"
            className="block mb-2 text-xs lg:text-sm font-medium text-red-600 dark:text-red-500"
          >
            <strong className="text-orange-500 text-md"> {num1} </strong> +{" "}
            <strong className="text-orange-500 text-md"> {num2} </strong> ?
          </label>
          <input
            value={userInput}
            onChange={(e) => handleInputChange(e)}
            type="text"
            min={1}
            maxLength={2}
            id="username-error"
            className="bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 w-9 p-1.5block text-center dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 outline outline-red-500 mt-2"
            placeholder={`0`}
          />
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {challengeFailed && (
              <span className="font-medium">😤 Oops!</span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default ReCAPTCHAComponent;
