
import PrivacyModal from './PrivacyModal';

const Footer = () => {

    let today = new Date();

    // RETURN 
    return (
        <footer className='w-full py-2 px-4 flex flex-col justify-between items-center'>
            <div className='mb-4'>
                <PrivacyModal className='privacy-btn' />
            </div>
            <small>PM&copy; {today.getFullYear()}</small>
        </footer>
    )
}

export default Footer;
