import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import PswsGetPageContext from "../context/PswsGetPageContextReducer/PswsGetPageContext";
import PswNewContext, {
  PswNewData,
  PswNewError,
  PswNewLoading,
  PswNewReset,
} from "../context/PswNewContextReducer/PswNewContext";
import GenerateContext, {
  GenerateDataContext,
} from "../context/GeneratePswContextReducer/GeneratePswContext";
import sanitizeInput from "../hooks/sanitizeInput";
import { toast } from "react-toastify";
import Spinner from "./ui/loaders/Spinner";
import NewPswForm from "./ui/forms/NewPswForm";

const NewPsw = () => {
  // CONTEXT
  const usrIn = React.useContext(UserInContext);
  // GENERATE CONTEXT
  const genPswFn = React.useContext(GenerateContext);
  const generated = React.useContext(GenerateDataContext);
  //   console.log(generated);

  // History
  const history = useNavigate();

  // USE EFFECT CHECK USER IS IN
  React.useEffect(() => {
    if (!usrIn) {
      history("/");
    }
  }, [usrIn, history]);

  // Values
  const [values, setValues] = React.useState({
    site: "",
    user: "",
    psw: `${generated}`,
    usr_id: usrIn ? usrIn.id : null,
  });

  // CONTEXT PSWS GET PAGE
  const fetchPsws = React.useContext(PswsGetPageContext);

  // CONTEXT PSW NEW
  const pswNewFn = React.useContext(PswNewContext);
  const pswNewData = React.useContext(PswNewData);
  const pswNewError = React.useContext(PswNewError);
  const pswNewLoading = React.useContext(PswNewLoading);
  const pswNewResetFn = React.useContext(PswNewReset);

  // Handle Change
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: sanitizeInput(value) });
    },
    [values]
  );

  // HANDLE SUBMIT
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (values.site === "" || values.user === "" || values.psw === "") {
        toast.error("Add values.");
        return;
      }
      // POST NEW PSW
      await pswNewFn(values);
    },
    [values, pswNewFn]
  );

  // USE EFFECT NEW PASSWORD
  useEffect(() => {
    if (pswNewData) {
      // console.log(pswNewData)
      // ALERT
      toast.success(pswNewData?.message);
      // UPDATE STATE
      (async () => await fetchPsws())();
      // RESET NEW PSW STATE
      pswNewResetFn();
      // REDIRECT TO HOME
      history("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pswNewData, history]);

  // USE EFFECT NEW PASSWORD ERROR
  useEffect(() => {
    if (pswNewError) {
      toast.error(pswNewError);
    }
  }, [pswNewError]);

  // CHANGE PASSWORD
  useEffect(() => {
    setValues({ ...values, psw: generated });
    // eslint-disable-next-line
  }, [generated]);

  // RETURN
  return (
    <main className="w-full flex-1 flex flex-col justify-start items-center overflow-y-auto p-2">
      <div className="w-full">
        <h1 className="text-2xl md:text-3xl  font-bold">New Password</h1>

        <div className="w-full flex justify-center mt-3">
          {pswNewLoading && <Spinner />}
        </div>

        <NewPswForm
          handleSubmit={handleSubmit}
          values={values}
          handleChange={handleChange}
          genPswFn={genPswFn}
        />
      </div>
    </main>
  );
};

export default NewPsw;
