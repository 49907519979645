import React, { useCallback, useContext } from "react";
import {
  SearchData,
  SearchResetData,
} from "../context/SearchContextReducer/SearchContext";
import Psw from "./Psw";
import { Link } from "react-router-dom";

const Search = () => {
  // CONTEXT SEARCH
  const searchData = useContext(SearchData);
  const resetSearchData = useContext(SearchResetData);

  // HANDLE RESET SEARCH DATA
  const handleResetSearchData = useCallback(async () => {
    await resetSearchData();
  }, [resetSearchData]);

  // RETURN
  return (
    <div className="w-full flex flex-col justify-start overflow-y-auto py-3 px-auto z-10 items-center flex-1">
      <h1 className="text-2xl md:text-3xl font-bold my-2">Search Results</h1>

      <div className="w-full flex justify-self-start items-center">
        <Link
          onClick={handleResetSearchData}
          className="py-2.5 px-6 text-sm bg-indigo-50 text-indigo-500 rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-indigo-100 flex justify-center items-center gap-2"
          to="/"
        >
          <svg
            className="translate-x[-2px] duration-300"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.5 12h-15m0 0 5.625-6M4.5 12l5.625 6" />
          </svg>
          Go Back Home
        </Link>
      </div>

      <div className="w-full p-2 rounded-lg">
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {searchData &&
              searchData.length > 0 && (
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Latest Customers
                  </h5>
                </div>
              ) &&
              searchData.map((p) => <Psw key={p.id} p={p} />)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Search;
