import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoginContext, {
  LoginLoadingContext,
  LoginErrorContext,
  LoginSuccessContext,
} from "../context/LoginContextReducer/LoginContext";
import {
  FirstLoginSuccessContext,
  FirstLoginSuccessResetContext,
} from "../context/FirstLoginContextReducer/FirstLoginContext";
// import sanitizeInput from '../hooks/sanitizeInput';
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import toast from "react-hot-toast";
import OtpWrapper from "./OtpWrapper";
import Spinner from "./ui/loaders/Spinner";

const Login = () => {
  const [otp, setOtp] = useState("");
  const { email } = useParams();
  const [userEmail, setUserEmail] = useState(email || "");
  const [isSuccess, setIsSuccess] = useState(false);
  //  console.log(userEmail);

  // HISTORY
  const history = useNavigate();

  // LOGIN CONTEXT
  const loginFn = useContext(LoginContext);
  const loginLoading = useContext(LoginLoadingContext);
  const loginError = useContext(LoginErrorContext);
  const loginSuccess = useContext(LoginSuccessContext);

  // FIRST LOGIN CONTEXT
  const firstLoginSuccess = useContext(FirstLoginSuccessContext);
  const firstLoginSuccessResetFn = useContext(FirstLoginSuccessResetContext);

  // console.log(firstLoginSuccess);
  // console.log(loginSuccess);

  // USER IN CONTEXT
  const usrIn = useContext(UserInContext);

  // CHECK IF USER IS LOGGED IN
  useEffect(() => {
    if (!firstLoginSuccess) {
      history("/");
    }
  }, [firstLoginSuccess, history]);

  // HANDLE SUBMIT
  const handleSubmit = useCallback(async () => {
    if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail) === false
    ) {
      // eslint-disable-line
      toast.error("Invalid Email.");
      return;
    }

    try {
      await loginFn(userEmail, otp);
    } catch (err) {
      toast.error(err?.message);
    }
  }, [loginFn, userEmail, otp]);

  // SUBMIT
  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp, handleSubmit]);

  // REACT USE EFFECT
  useEffect(() => {
    if (loginError !== "") {
      // console.log(loginError.message)
      toast.error(loginError);
    }
  }, [loginError]);

  // REACT USE EFFECT
  useEffect(() => {
    if (usrIn && loginSuccess === "LOGIN OK" && !isSuccess) {
      setOtp("");
      setUserEmail("");
      setIsSuccess(true);
    }
    // eslint-disable-next-line
  }, [usrIn, loginSuccess]);

  // REACT USE EFFECT
  useEffect(() => {
    if (isSuccess) {
      toast.success("Login OK");
      firstLoginSuccessResetFn();
      history("/");
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  // FORM REF
  const formRef = useRef(null);
  // console.log(formRef)

  // RETURN
  return (
    <div className="w-full flex flex-col justify-center items-center flex-1">
      

      <form
        className="relative flex flex-col justify-center items-center w-full h-full"
        ref={formRef}
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
      >
        <h2 className="text-sm/3 mb-4 text-center">{userEmail} </h2>
        
        <div className="text-center text-lg my-4">
          {loginLoading && <Spinner />}
        </div>

        <div className="relative w-full h-auto">
          <OtpWrapper setOtp={setOtp} />
        </div>
      </form>
    </div>
  );
};

export default Login;
