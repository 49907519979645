import DarkContext, {
  DarkSetContext,
} from "../context/DarkContextReducer/DarkContext";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import LogoutContext from "../context/LogoutContextReducer/LogoutContext";
import Cookies from "universal-cookie";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Header = ({ title }) => {
  const [expiration, setExpiration] = React.useState(0);

  // NAVIGATE
  const navigate = useNavigate();

  // DARK CONTEXT
  const isDark = useContext(DarkContext);
  const setDarkFn = useContext(DarkSetContext);
  // AUTH
  const usrIn = useContext(UserInContext);
  // console.log(usrIn)

  // LOGOUT CONTEXT
  const logoutFn = useContext(LogoutContext);

  // Import audio file
  let lightSwitch = useMemo(() => new Audio("/sounds/light-switch.mp3"), []);

  // HANDLE DARK MODE
  const handleDark = useCallback(() => {
    // Add dark attribute
    if (!isDark) {
      lightSwitch.currentTime = 0;
      lightSwitch.play();
      setDarkFn("SET_DARK");
    } else {
      lightSwitch.currentTime = 0;
      lightSwitch.play();
      setDarkFn("SET_LIGHT");
    }
  }, [isDark, lightSwitch, setDarkFn]);

  // CHECK TKN EXPIRATION STATE
  useEffect(() => {
    // COOKIE HOOK
    const cookies = new Cookies();
    let tkn = cookies.get("tkn");
    if (tkn) {
      let dec = jwt_decode(tkn);
      setExpiration(dec?.exp * 1000 - 60000);
    }
  }, [usrIn]);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      const cookies = new Cookies();
      let tkn = cookies.get("tkn");
      if (tkn && expiration) {
        if (Date.now() >= new Date(expiration).getTime()) {
          // console.log('Expired');
          setExpiration(0);
          logoutFn();
          // console.log('expired');
          clearInterval(interval);
          if (window) {
            window.location.replace("/");
          }
        } else {
          // console.log('valid');
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiration, logoutFn]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // console.log(event.key);
      // Log shift 
      if (event.ctrlKey && event.shiftKey && event.key === "L") {
        event.preventDefault();
        navigate("/auth/firstlogin");
      }
    };

    if (window) {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (window) {
        window.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [navigate]);

  // GO TO PROFILE
  const gotoProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  // GO HOME
  const goHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  // RETURN
  return (
    <header className="w-full px-1 flex justify-around text-2xl md:text-4xl items-center p-2">
      <div onClick={goHome} className="flex justify-center items-center flex-grow-0 p-1 cursor-pointer">
        <h1 className="font-bold font-[Allison] leading-3 tracking-wider text-purple-600">
          {title}
        </h1>
      </div>
      <div className="flex flex-col justify-center items-center">
        {usrIn && (
          <>
            <small onClick={gotoProfile} className="cursor-pointer">
              {usrIn.username}
            </small>
            <small className="text-xs">
              {" "}
              <strong>Exp: </strong> {new Date(expiration).toLocaleString()}
            </small>
          </>
        )}
      </div>
      <div
        className="flex justify-center items-center flex-grow-0 p-1 cursor-pointer opacity-75"
        onClick={handleDark}
      >
        {isDark ? (
          <img className="object-contain w-8 h-8" src="/imgs/sun.svg" alt="sun" />
        ) : (
          <img className="object-contain w-8 h-8" src="/imgs/moon.svg" alt="moon" />
        )}
      </div>
    </header>
  );
};

export default Header;
