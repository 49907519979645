import React, { useCallback, useContext, useEffect } from "react";
import "./Backup.css";
import PswsGetAllContext, {
  PswsGetAllLoading,
  PswsGetAllError,
  PswsGetAllData,
} from "../context/PswsGetAllContextReducer/PswsGetAllContext";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BckDownloadContext, {
  BckDownloadLoading,
  BckDownloadError,
} from "../context/BackupContextReducer/BckDownloadContext";
import Spinner from "./ui/loaders/Spinner";
import UploadBckForm from "./ui/forms/UploadBckForm";

const Backup = () => {
  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT PSWS GET ALL
  const fetchAllPswsFn = useContext(PswsGetAllContext);
  const pswsGetAllLoading = useContext(PswsGetAllLoading);
  const pswsGetAllError = useContext(PswsGetAllError);
  const pswsGetAllData = useContext(PswsGetAllData);
  // const pswsGetAllResetFn = useContext(PswsGetAllResetData);

  // console.log(pswsGetAllData)

  // BCK DOWNLOAD
  const bckDownloadFn = useContext(BckDownloadContext);
  const bckDownloadLoading = useContext(BckDownloadLoading);
  const bckDownloadError = useContext(BckDownloadError);
  // console.log(bckDownloadLoading);
  // console.log(bckDownloadError);

  // CONTEXT USER IN SET
  const usrIn = useContext(UserInContext);

  // BACKUP ERROR
  useEffect(() => {
    if (bckDownloadError !== "") {
      toast.error(bckDownloadError);
    }
  }, [bckDownloadError]);

  // CHECK IF USR IN
  useEffect(() => {
    if (usrIn == null) {
      navigate("/");
    }
  }, [usrIn, navigate]);

  // FETCH ALL PSWS
  const fetchAllPsws = useCallback(async () => {
    await fetchAllPswsFn();
  }, [fetchAllPswsFn]);

  useEffect(() => {
    (async () => await fetchAllPswsFn())();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // BACKUP ERROR
  useEffect(() => {
    if (pswsGetAllError !== "") {
      toast.error(pswsGetAllError);
    }
  }, [pswsGetAllError]);

  // BCK PRINT
  const bckPrint = useCallback(() => {
    window.print();
  }, []);

  // HANDLE DOWNLOAD
  const handleDownload = useCallback(async () => {
    bckDownloadFn();
  }, [bckDownloadFn]);

  // RETURN
  return (
    <div className="Backup relative w-full flex flex-col justify-start items-center flex-1 overflow-auto py-3 px-2 z-10">
      <h1 className="text-2xl md:text-3xl font-bold my-2">Backup</h1>

      <div className="w-full flex flex-row items-center justify-around p-2">
        {/* PRINT  */}
        <div className="flex justify-center items-center gap-1">
          <button
            className="py-1.5 px-3.5 text-xs max-h-max bg-indigo-500 text-white rounded-full cursor-pointer font-medium leading-5 text-center shadow-xs transition-all duration-500 hover:bg-indigo-700"
            onClick={bckPrint}
          >
            {" "}
            <span>Print</span>{" "}
          </button>
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 10V5a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v5m15 0H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1" />
            <path d="M17.5 20v-3a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1v3m-4-7h2" />
          </svg>
        </div>

        {/* DOWNLOAD PASSWORD     */}
        <div className="flex justify-center items-center gap-1">
          <button
            className="py-1.5 px-3.5 text-xs max-h-max bg-indigo-500 text-white rounded-full cursor-pointer font-medium leading-5 text-center shadow-xs transition-all duration-500 hover:bg-indigo-700 flex justify-center items-center"
            onClick={handleDownload}
          >
            <span>Download</span>
          </button>
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 16.004V17a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-1M12 4.5v11m3.5-3.5L12 15.5 8.5 12" />
          </svg>
        </div>

        {/* UPLOAD FILE  */}
        <UploadBckForm fetchAllPsws={fetchAllPsws} />
      </div>

      {pswsGetAllLoading && (
        <div className="bck-loading">
          <Spinner />
        </div>
      )}

      {bckDownloadLoading && (
        <div className="bck-loading">
          <Spinner />
        </div>
      )}

      <div className="w-full flex flex-col">
        <div className="w-full overflow-x-auto p-4">
          <div className="min-w-full inline-block align-middle">
            <div className="overflow-hidden  border rounded-lg border-gray-300">
              <table className="table-auto min-w-full rounded-xl">
                <thead>
                  <tr className="bg-gray-50">
                    <th
                      scope="col"
                      className="p-5 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"
                    >
                      Site
                    </th>
                    <th
                      scope="col"
                      className="p-5 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="p-5 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"
                    >
                      Psw
                    </th>
                    <th
                      scope="col"
                      className="p-5 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 ">
                  {pswsGetAllData &&
                    pswsGetAllData.length > 0 &&
                    pswsGetAllData.map((item, index) => {
                      return (
                        <tr
                          className="bg-white transition-all duration-500 hover:bg-gray-50"
                          key={index}
                        >
                          <td className="p-5 whitespace-nowrap text-sm leading-6 font-medium text-gray-900 ">
                            {item.site}
                          </td>
                          <td className="p-5 whitespace-nowrap text-sm leading-6 font-medium text-gray-900 ">
                            {item.user}
                          </td>
                          <td className="p-5 whitespace-nowrap text-sm leading-6 font-medium text-gray-900 ">
                            {item.psw}
                          </td>
                          <td className="p-5 whitespace-nowrap text-sm leading-6 font-medium text-gray-900 ">
                            {new Date(item.created_at).toLocaleDateString("it")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backup;
