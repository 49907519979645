import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import FirstLoginContext, {
  FirstLoginLoadingContext,
  FirstLoginErrorContext,
  FirstLoginSuccessContext,
  FirstLoginMessageContext,
} from "../context/FirstLoginContextReducer/FirstLoginContext";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { toast } from "react-toastify";
import ReCAPTCHAComponent from "./ui/reCAPTCHA/ReCAPTCHA";
import LoginForm from "./ui/forms/LoginForm";

const FirstLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  // HISTORY
  const history = useNavigate();

  // LOGIN CONTEXT
  const firstLoginFn = useContext(FirstLoginContext);
  const firstLoginLoading = useContext(FirstLoginLoadingContext);
  const firstLoginError = useContext(FirstLoginErrorContext);
  const firstLoginSuccess = useContext(FirstLoginSuccessContext);
  const firstLoginMessage = useContext(FirstLoginMessageContext);

  // USER IN CONTEXT
  const usrIn = useContext(UserInContext);

  // CHECK IF USER IS LOGGED IN
  useEffect(() => {
    if (usrIn !== null) {
      history("/");
    }
  }, [usrIn, history]);

  // HANDLE SUBMIT
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
        // eslint-disable-line
        toast.error("Invalid value.");
        return;
      }
      if (password.length < 6) {
        toast.error("Required.");
        return;
      }
      try {
        setDisabled(true);
        await firstLoginFn(email, password);
      } catch (err) {
        toast.error(err?.message);
      } finally {
        setDisabled(false);
      }
    },
    [firstLoginFn, email, password]
  );

  // REACT USE EFFECT
  useEffect(() => {
    if (firstLoginError !== "") {
      // console.log(loginError.message)
      toast.error(firstLoginError);
    }
  }, [firstLoginError]);

  // REACT USE EFFECT
  useEffect(() => {
    // console.log('success',firstLoginSuccess);
    // console.log('success',firstLoginMessage);

    if (firstLoginSuccess && firstLoginMessage === "OK") {
      // console.log('success',firstLoginSuccess);
      // console.log('success',firstLoginMessage);

      toast.success(firstLoginMessage);

      setTimeout(() => {
        history(`/auth/login/${email}`);
      }, 1000);

      setEmail("");
      setPassword("");
    }

    if (!firstLoginSuccess && firstLoginMessage !== "") {
      // console.log('success',firstLoginSuccess);
      // console.log('success',firstLoginMessage);
      toast.error(firstLoginMessage);
    }

    // eslint-disable-next-line
  }, [firstLoginSuccess, firstLoginMessage]);

  // FORM REF
  const formRef = useRef(null);

  // RETURN
  return (
    <div className="w-full flex flex-col justify-center items-center flex-1">
      <h1 className="mb-4 text-center text-2xl md:text-4xl">Lorem Ipsum</h1>

      <LoginForm
        formRef={formRef}
        handleSubmit={handleSubmit}
        firstLoginLoading={firstLoginLoading}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        disabled={disabled}
      />

      <ReCAPTCHAComponent setDisabled={setDisabled} />
    </div>
  );
};
export default FirstLogin;
