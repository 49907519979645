import React, { createContext, useCallback, useReducer, useState } from "react";
import generatePswReducer from "./GeneratePswReducer";

const GenerateContext = createContext();
export const GenerateLengthContext = createContext();
export const GenerateUpperContext = createContext();
export const GenerateNumContext = createContext();
export const GenerateSymContext = createContext();
export const GenerateLowerContext = createContext();
export const GenerateDataContext = createContext();
//  SET
export const GenerateSetLengthContext = createContext();
export const GenerateSetUpperContext = createContext();
export const GenerateSetNumContext = createContext();
export const GenerateSetSymContext = createContext();
export const GenerateSetDataContext = createContext();
export const GenerateIsDisabled = createContext();

// Message success
export const GenerateMessageContext = createContext();
export const GenerateErrorMessageContext = createContext();

export const GenerateProvider = ({ children }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  // INITIAL STATE
  const initialState = {
    pswLength: 13,
    upper: true,
    num: true,
    sym: false,
    generated: "",
    lower: true,
    message: "",
    error: "",
  };

  // REDUCER
  const [state, dispatch] = useReducer(generatePswReducer, initialState);

  // GENERATE SYMBOL
  let genSym = useCallback(() => {
    let symbols = "@#£%&*-+";
    return symbols[Math.floor(Math.random() * symbols.length)];
  }, []);
  // GEN NUM
  let genNum = useCallback(() => {
    return String.fromCharCode(Math.floor(Math.random() * 10) + 48);
  }, []);
  // GEN UPPER
  let genUp = useCallback(() => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  }, []);
  // GEN LOWER
  let genLower = useCallback(() => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
  }, []);

  // GENERATE PASSWORD
  let genPswFn = useCallback(() => {
    try {
      setIsDisabled(true);

      // Check password length
      if (state.pswLength < 8) {
        dispatch({
          type: "PSW_ERROR",
          error: "Password length must be at least 8 characters",
        });
        return;
      } else if (state.pswLength > 33) {
        dispatch({
          type: "PSW_ERROR",
          error: "Password length must be less than 33 characters",
        });
        return;
      }

      // Empty Pass
      let psw = "";
      
      dispatch({ type: "PSW_GENERATED", psw: "" });

      // Type Count
      let typesCount = state.lower + state.upper + state.num + state.sym;
      let typeArr = [
        { lower: state.lower },
        { upper: state.upper },
        { num: state.num },
        { sym: state.sym },
      ].filter((i) => Object.values(i)[0]);

      // Random function
      const randomFunGen = {
        lower: genLower,
        upper: genUp,
        num: genNum,
        sym: genSym,
      };
      for (let i = 0; i < state.pswLength; i += typesCount) {
        // eslint-disable-next-line
        typeArr.forEach((type) => {
          const fnName = Object.keys(type)[0];
          psw += randomFunGen[fnName]();
        });
      }
      //
      let finalPsw = psw.slice(0, state.pswLength);
      // console.log(psw);
      // console.log(finalPsw);
      // DISPATCH GENERATED PASSWORD
      dispatch({ type: "PSW_GENERATED", psw: finalPsw });

      // DISPATCH MESSAGE
      dispatch({
        type: "PSW_MESSAGE",
        message: "Password generated successfully",
      });

      // COPY
      let copyPsw = () => {
        navigator.clipboard.writeText(finalPsw);
      };
      copyPsw();
    } catch (e) {
      console.log(e);
      dispatch({
        type: "PSW_ERROR",
        error: e.message || "Something went wrong",
      });
    } finally {
      setTimeout(() => {
          // DISPATCH RESET
      dispatch({ type: "PSW_MESSAGE", message: "" });
      dispatch({ type: "PSW_ERROR", error: "" });
      }, 3000);
      setIsDisabled(false);
    }
  }, [
    dispatch,
    state.pswLength,
    state.lower,
    state.upper,
    state.num,
    state.sym,
    genSym,
    genNum,
    genUp,
    genLower,
  ]);

  // DISPATCH PASSWORD LENGTH
  let setPswLength = useCallback(
    (length) => {
      dispatch({ type: "PSW_LENGTH", pswLength: length });
    },
    [dispatch]
  );
  // DISPATCH UPPER
  let setUpper = useCallback(
    (upper) => {
      dispatch({ type: "PSW_UPPER", upper: upper });
    },
    [dispatch]
  );
  // DISPATCH NUM
  let setNum = useCallback(
    (num) => {
      dispatch({ type: "PSW_NUM", num: num });
    },
    [dispatch]
  );
  // DISPATCH SYM
  let setSym = useCallback(
    (sym) => {
      dispatch({ type: "PSW_SYM", sym: sym });
    },
    [dispatch]
  );

  // RETURN
  return (
    <GenerateContext.Provider value={genPswFn}>
      <GenerateLengthContext.Provider value={state.pswLength}>
        <GenerateSetLengthContext.Provider value={setPswLength}>
          <GenerateUpperContext.Provider value={state.upper}>
            <GenerateSetUpperContext.Provider value={setUpper}>
              <GenerateNumContext.Provider value={state.num}>
                <GenerateSetNumContext.Provider value={setNum}>
                  <GenerateSymContext.Provider value={state.sym}>
                    <GenerateSetSymContext.Provider value={setSym}>
                      <GenerateDataContext.Provider value={state.generated}>
                        <GenerateLowerContext.Provider value={state.lower}>
                          <GenerateIsDisabled.Provider value={isDisabled}>
                            <GenerateErrorMessageContext.Provider
                              value={state.error}
                            >
                              <GenerateMessageContext.Provider
                                value={state.message}
                              >
                                {children}
                              </GenerateMessageContext.Provider>
                            </GenerateErrorMessageContext.Provider>
                          </GenerateIsDisabled.Provider>
                        </GenerateLowerContext.Provider>
                      </GenerateDataContext.Provider>
                    </GenerateSetSymContext.Provider>
                  </GenerateSymContext.Provider>
                </GenerateSetNumContext.Provider>
              </GenerateNumContext.Provider>
            </GenerateSetUpperContext.Provider>
          </GenerateUpperContext.Provider>
        </GenerateSetLengthContext.Provider>
      </GenerateLengthContext.Provider>
    </GenerateContext.Provider>
  );
};

export default GenerateContext;
