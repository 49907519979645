import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { PswGetSingleProvider } from "./context/PswGetSingleContextReducer/PswGetSingleContext";
import { PswUpdateProvider } from "./context/PswUpdateContextReducer/PswUpdateContext";
import { PswDeleteProvider } from "./context/PswDeleteContextReducer/PswDeleteContext";
import { PswNewProvider } from "./context/PswNewContextReducer/PswNewContext";
import { PswGetPswProvider } from "./context/PswGetPswContextReducer/PswGetPswContext";
import { PswsGetPageProvider } from "./context/PswsGetPageContextReducer/PswsGetPageContext";
import { PswsGetAllProvider } from "./context/PswsGetAllContextReducer/PswsGetAllContext";
import { SearchProvider } from "./context/SearchContextReducer/SearchContext";
import { DarkProvider } from "./context/DarkContextReducer/DarkContext";
import { RegisterProvider } from "./context/RegisterContextReducer/RegisterContext";
import { LogoutProvider } from "./context/LogoutContextReducer/LogoutContext";
import { LoginProvider } from "./context/LoginContextReducer/LoginContext";
import { FirstLoginProvider } from "./context/FirstLoginContextReducer/FirstLoginContext.js";
import { ResetProvider } from "./context/ResetContextReducer/ResetContext.js";
import { GenerateProvider } from "./context/GeneratePswContextReducer/GeneratePswContext";
import { BckDownloadProvider } from "./context/BackupContextReducer/BckDownloadContext";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { disableRightClick } from "./hooks/useDisableRightClick";
import { Toaster } from "react-hot-toast";

if (process.env.REACT_APP_ENV === "production") {
  disableReactDevTools();
  disableRightClick();
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
  <React.StrictMode>
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <PswsGetPageProvider>
        <PswsGetAllProvider>
          <PswGetSingleProvider>
            <PswUpdateProvider>
              <PswDeleteProvider>
                <PswNewProvider>
                  <PswGetPswProvider>
                    <SearchProvider>
                      <LogoutProvider>
                        <DarkProvider>
                          <GenerateProvider>
                            <RegisterProvider>
                              <LoginProvider>
                                <FirstLoginProvider>
                                  <ResetProvider>
                                      <BckDownloadProvider>
                                        <ToastContainer />
                                        <Toaster
                                          position="top-center"
                                          reverseOrder={false}
                                          gutter={8}
                                          containerClassName=""
                                          containerStyle={{}}
                                          toastOptions={{
                                            // Define default options
                                            className: "",
                                            duration: 5000,
                                            style: {
                                              background: "#363636",
                                              color: "#fff",
                                            },

                                            // Default options for specific types
                                            success: {
                                              duration: 3000,
                                              theme: {
                                                primary: "green",
                                                secondary: "black",
                                              },
                                            },
                                          }}
                                        />
                                        <App />
                                      </BckDownloadProvider>
                                  </ResetProvider>
                                </FirstLoginProvider>
                              </LoginProvider>
                            </RegisterProvider>
                          </GenerateProvider>
                        </DarkProvider>
                      </LogoutProvider>
                    </SearchProvider>
                  </PswGetPswProvider>
                </PswNewProvider>
              </PswDeleteProvider>
            </PswUpdateProvider>
          </PswGetSingleProvider>
        </PswsGetAllProvider>
      </PswsGetPageProvider>
    </BrowserRouter>
  </React.StrictMode>
);
