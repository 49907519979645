import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { UserInContext } from "../context/LogoutContextReducer/LogoutContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ResetContext, {
  ResetLoadingContext,
  ResetErrorContext,
  ResetMessageContext,
  ResetSuccessContext,
} from "../context/ResetContextReducer/ResetContext";
import ResetPasswordForm from "./ui/forms/ResetPasswordForm";

const Reset = () => {
  const { email } = useParams();
  const [userEmail, setUserEmail] = useState(email || "");
  const [password, setPassword] = useState("");

  // HISTORY
  const history = useNavigate();

  // RESET CONTEXT
  const resetFn = useContext(ResetContext);
  const resetLoading = useContext(ResetLoadingContext);
  const resetError = useContext(ResetErrorContext);
  const resetSuccess = useContext(ResetSuccessContext);
  const resetMessage = useContext(ResetMessageContext);

  // USER IN CONTEXT
  const usrIn = useContext(UserInContext);

  // CHECK IF USER IS LOGGED IN
  useEffect(() => {
    if (!usrIn) {
      history("/");
    }
  }, [usrIn, history]);

  // HANDLE SUBMIT
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail) ===
        false
      ) {
        // eslint-disable-line
        toast.error("Invalid Email.");
        return;
      }

      if(password.length < 6){
        // eslint-disable-line
        toast.error("Password must be at least 6 characters.");
        return;
      }

      try {
        await resetFn(userEmail, password);
      } catch (err) {
        toast.error(err?.message);
      }
    },
    [resetFn, userEmail, password]
  );

  // REACT USE EFFECT
  useEffect(() => {
    if (resetError !== "") {
      // console.log(loginError.message)
      toast.error(resetError);
    }
  }, [resetError]);

  // REACT USE EFFECT
  useEffect(() => {
    // console.log('success',resetSuccess);
    // console.log('success',resetMessage);

    let timeout;

    if (resetSuccess && resetMessage !== "") {
      // console.log('success',resetSuccess);
      // console.log('success',resetMessage);

      toast(resetMessage);

      timeout = setTimeout(() => {
        history(`/profile`);
      }, 1000);
      setUserEmail("");
      setPassword("");
    }

    if (!resetSuccess && resetMessage !== "") {
      // console.log('success',resetSuccess);
      // console.log('success',resetMessage);
      toast(resetMessage);
    }

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  }, [resetSuccess, resetMessage]);

  // FORM REF
  const formRef = useRef(null);
  // console.log(formRef)

  // RETURN
  return (
    <div className="w-full flex flex-col justify-start overflow-y-auto py-3 px-auto z-10 items-center flex-1">
      <h1 className="text-2xl md:text-3xl font-bold my-2">Reset Password</h1>

      <ResetPasswordForm formRef={formRef} handleSubmit={handleSubmit} resetLoading={resetLoading} userEmail={userEmail} password={password} setPassword={setPassword} />
     
    </div>
  );
};
export default Reset;
