import React from 'react'

const Pagination = ({ page, lastPage, pagesRange, handlePagNumb, handleNext, handlePrev, handleFirstPage, handleLastPage, search }) => {
  return (
    <>
      {!search && (
        <div className="relative flex justify-center items-center w-full h-[60px] p-3 my-6 mx-auto z-10">
          {page > 1 && (
            <div className="w-full border-none outline-none bg-transparent duration-300 cursor-pointer flex justify-center items-center mx-1 hover:translate-x-[-2px]">
              <button onClick={handleFirstPage}>
                <img src="/imgs/chevronLeft.svg" className="w-7" alt="left" /> First
              </button>
            </div>
          )}

          {page > 1 && (
            <div className="w-full border-none outline-none bg-transparent duration-300 cursor-pointer flex justify-center items-center mx-1 hover:translate-x-[-2px]">
              <button onClick={handlePrev}>
                <img src="/imgs/chevronLeft.svg" className="w-7" alt="left" /> Prev
              </button>
            </div>
          )}

          {pagesRange.map((i) => (
            <span
              key={i}
              className={`${i === page && "bg-purple-700 text-white"} w-7 h-7 grid items-center rounded-full  mx-1 cursor-pointer hover:bg-green-600 hover:text-white duration-200`}
              onClick={() => handlePagNumb(i)}
            >
              {i}
            </span>
          ))}

          {/* NEXT  */}

          {page < lastPage && (
            <div className="w-full border-none outline-none bg-transparent duration-300 cursor-pointer flex justify-center items-center mx-1 hover:translate-x-[2px]">
              <button onClick={handleNext}>
                Next <img src="/imgs/chevronRight.svg" className="w-7" alt="right" />
              </button>
            </div>
          )}

          {page < lastPage && (
            <div className="w-full border-none outline-none bg-transparent duration-300 cursor-pointer flex justify-center items-center  mx-1 hover:translate-x-[2px]">
              <button onClick={handleLastPage}>
                Last <img src="/imgs/chevronRight.svg" className="w-7" alt="right" />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Pagination